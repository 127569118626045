/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Box, useTheme} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {IDeviceInfoResponse, IMeasurementResult, MEASUREMENT_TYPE,} from 'models/iot-devices';
import {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useStore} from 'stores/store-hooks';
import {AutomationStatusGadget} from './automation-status-gadget';
import {getBatteryLevelProps, getFeedUsageProps, getSiloLevelProps,} from './feeder-gadget-utils';
import toastStore from "../../../../stores/toast-store";

export const FeederGadgets = observer(() => {
  const theme = useTheme();
  const intl = useIntl();
  const basinStore = useStore('basinStore');
  const iotStore = useStore('iotStore');
  const [deviceInfo, setDeviceInfo] = useState<IDeviceInfoResponse | null>(
    null
  );
  const [
    siloSensorMeasurements,
    setSiloSensorMeasurements
  ] = useState<IMeasurementResult[]>([]);
  const [
    batteryLevelMeasurements,
    setBatteryLevelMeasurements
  ] = useState<IMeasurementResult[]>([]);
  const [
    feedUsageMeasurements,
    setFeedUsageMeasurements
  ] = useState<IMeasurementResult[]>([]);

  const { hasPendingForceFeedCommand } = iotStore;
  const [iotLastLoaded, setIotLastLoaded] = useState<Date | null>(null);
  const loadIotDataForGadgets = useCallback(async () => {
    /* Calling the loadMeasurements causes hasPendingForceFeedCommand to update which causes this callback to run in loop
     * To concur this, use a component value to check, when the update was last called from component and if it's less than
     * x ms from last update, don't run the update */
    if (iotLastLoaded != null && iotLastLoaded.getTime() >= new Date().getTime() - 6000) return;

    if (basinStore.selectedBasin?.supportunit_id) {
      const response = await iotStore.loadMeasurements(
        basinStore.selectedBasin?.supportunit_id,
        1,
        10,
        MEASUREMENT_TYPE.SiloLevelSensor
      );
      if (response.status === 200) {
        setSiloSensorMeasurements(response.data || []);
      }

      const responseBattery = await iotStore.loadMeasurements(
        basinStore.selectedBasin?.supportunit_id,
        1,
        10,
        MEASUREMENT_TYPE.BatteryLevel
      );
      if (responseBattery.status === 200) {
        setBatteryLevelMeasurements(responseBattery.data || []);
      }

      const responseFeedUsage = await iotStore.loadMeasurements(
        basinStore.selectedBasin?.supportunit_id,
        1,
        10,
        MEASUREMENT_TYPE.CumulativeFeedAmount
      );
      if (responseFeedUsage.status === 200) {
        // Only use measurements that were sent today
        const midnight = new Date().setHours(0, 0, 0, 0);

        setFeedUsageMeasurements(
          (responseFeedUsage.data || []).filter(m => m.value[0] > midnight)
        );
      }

      const responseDInfo = await iotStore.loadDeviceInfo(
        basinStore.selectedBasin
      );
      if (responseDInfo.status === 200) {
        if (!responseDInfo.data?.deviceConfiguration) {
          toastStore.setToast('DeviceInfoFetchError', 'warning', undefined, true);
        }
        setDeviceInfo(responseDInfo.data)
        if (responseDInfo?.data?.deviceConfiguration)
          basinStore.autoFeedOn = responseDInfo?.data?.deviceConfiguration.autofeed === 1;
        if (responseDInfo.data?.custom_props?.parsedStatus?.amount_fed || feedUsageMeasurements[0])
          basinStore.amountFedToday = responseDInfo.data?.custom_props?.parsedStatus?.amount_fed ||
            feedUsageMeasurements[0]?.value[1] || 0;
        setIotLastLoaded(new Date());
      }
    }
  }, [iotStore, basinStore, feedUsageMeasurements, iotLastLoaded, setIotLastLoaded]);

  useEffect(() => {
    // reload data if force feed status changes true -> false.
    if (!hasPendingForceFeedCommand) loadIotDataForGadgets();
  }, [loadIotDataForGadgets, hasPendingForceFeedCommand]);

  const batteryProps = getBatteryLevelProps(
    deviceInfo,
    batteryLevelMeasurements,
    intl,
    theme
  );
  const siloProps = getSiloLevelProps(siloSensorMeasurements, intl, theme);
  const feedStatusProps = getFeedUsageProps(
    deviceInfo,
    feedUsageMeasurements,
    intl,
    theme
  );

  return (
    <Box
      css={(theme) => css`
        flex: 1 1 auto;
        border-radius: 5px;
        margin: ${theme.spacing(2)}px ${theme.spacing(0)}px
          ${theme.spacing(0)}px ${theme.spacing(2)}px;
      `}
    >
      <AutomationStatusGadget {...batteryProps} />
      <AutomationStatusGadget {...feedStatusProps} />
      <AutomationStatusGadget {...siloProps} />
    </Box>
  );
});
